.loading-anim-cards {
  display: flex;
}

.loading-anim-card {
  margin: 10px;
  width: 300px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.loading-anim-card .loading-anim-image img {
  max-width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.loading-anim-card .loading-anim-content {
  padding: 15px;
}

.loading-anim-card.loading-anim-is-loading .loading-anim-image,
.loading-anim-card.loading-anim-is-loading h2,
.loading-anim-card.loading-anim-is-loading p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s loading-anim-shine linear infinite;
}

.loading-anim-card.loading-anim-is-loading .loading-anim-image {
  height: 100px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.loading-anim-card.loading-anim-is-loading h2 {
  height: 25px;
}

.loading-anim-card.loading-anim-is-loading p {
  height: 50px;
}

@keyframes loading-anim-shine {
  to {
    background-position-x: -200%;
  }
}
