/* .custom-spinner {
  width: 8px;
  height: 60px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: #ddd;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.custom-spinner::after,
.custom-spinner::before {
  content: '';
  width: 8px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}


.custom-spinner::before {
  left: -20px;
  background: #ddd;
  animation-delay: 0s;
}



.custom-spinner::after {
  left: 20px;
  background: #ddd;
}

@keyframes animloader {
  0% {
    height: 48px;
  }

  100% {
    height: 4px;
  }
}

@media screen and (max-width: 1222px) {
  .custom-img-login {
    display: none !important;
  }
} */


/* HTML: <div class="loader"></div> */
.custom-spinner {
  width: 48px;
  height: 48px;
  background: #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.custom-spinner::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 6px;
  top: 10px;
  width: 12px;
  height: 12px;
  color: #4663ac;
  background: currentColor;
  border-radius: 50%;
  box-shadow: 25px 2px, 10px 22px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 