span.text-danger.danger-title {
  font-size: 11px;
}

.dashboardSubChildCard {
  background-color: #f2f2f8;
  padding: 15px 20px;
  color: black;
  border-radius: 5px;
}

.disable-chart {
  margin: auto;
  opacity: 0.4 !important;
  filter: drop-shadow(2px 4px 6px black) !important;
  margin-bottom: 20px;
  height: 260px;
  width: 260px;
}

.dashboardSubChildCard:hover {
  background-color: #b6b9c682;
}

.all-center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SidebarSearchheader {
  max-width: 100%;
  color: #fff;
  height: 45px;
  text-decoration: none;
  color: #000;
  background: #ffff;
  padding: 30.5px 10px;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
}

.single-site-card {
  height: 107px;
}

h3.SidebarSearch-title {
  font-size: 20px;
  max-width: 90%;
  font-weight: 500;
}

.badge {
  display: inline-block;
  background-color: #898989;
  border-color: none;
  padding: 11px;
  border-radius: 2px;
  margin-right: 2px;
  color: #fff;
}

.badge-key {
  font-weight: bold;
  font-size: 10px;
  font-weight: 500;
}

.badge-value {
  margin-left: 5px;
  font-size: 10px;
}

.bg-success {
  background: #09ad0e !important;
}

#global-loader {
  position: fixed;
  z-index: 50000;
  background: none !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.readonly {
  background-color: #dddddd75 !important;
  cursor: default;
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0 / 65%) !important;
  user-select: none;
}

.card.card-none.card {
  background: none !important;
  box-shadow: none;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border-bottom: 1px solid #eaedf1 !important;
  outline: none !important;
  margin-left: -11px !important;
  border-radius: 0px;
  background: #fff !important;
}

.card-Div.card-body {
  cursor: pointer;
}

button.modal-effect.d-grid.mb-3.upload-btn.btn.btn-danger {
  font-size: 18px;
  border: 0px solid #fff;
}

.table-input {
  padding: 9px;
  background: #fff;
  color: #000;
  width: 100%;
  border: 1px solid #cfcfcf;
  min-width: 100px !important;
  font-size: 15px;
}

.Dps-data {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
}

.Dps-data:hover {
  background: #e4e4e4;
}

.Dps-data p {
  margin: 0px;
  padding: 10px;
}

.module-height {
  max-height: 290px !important;

  overflow-y: scroll;
}

.bottom-toal {
  font-size: 16px;
  padding: 10px;
  margin: 0px;
  text-align: center;
  font-weight: 600;
  color: #76839a;
}

.cardheader-table.card-header {
  padding: 10px 15px;
  background: var(--primary-bg-color);
  color: #fff;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px;
}

.UpdateValueInput {
  border: 1px solid #e23434;
  padding: 10px;
  width: 100%;
  color: #e23434;
}

.table-inputGreen {
  border: 1px solid green;
  padding: 10px;
  width: 100%;
  color: green;
}

.table-inputRed {
  border: 1px solid #e23434;
  color: #e23434;
  padding: 10px;
  width: 100%;
}

textarea#Remarks {
  border: 3px solid #dddd;
  width: 30%;
  margin: 15px 0px;
}

div#description {
  color: red;
  font-size: 18px;

  font-weight: 400;
}

p.warrningmessage {
  font-size: 16px;
  font-weight: 500;
  padding: 13px 0px;
}

input.dynamicinput {
  padding: 10px;
  border: 1px solid #533e3e;
}

@media screen and (min-width: 768px) {
  .dashboard-chart-height {
    min-height: 330px;
  }
}

@media screen and (min-width: 1400px) {
  .dashboard-chart-height {
    min-height: 400px;
  }
}

@media screen and (min-width: 1600px) {
  .dashboard-chart-height {
    min-height: 450px;
  }
}

@media screen and (min-width: 1994px) {
  .dashboard-chart-height {
    min-height: 500px;
  }
}

.table-container {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0px;
}

.table th,
.table td {
  padding: 4px;
  text-align: left;
  border-bottom: 1px solid #aeb1bd;
  border-right: none !important;
}

.table th {
  font-weight: bold;
}

.fuelprice-tr {
  width: 100%;
  display: flex;
}

tbody td {
  min-width: 15%;
}

.table-container.table-responsive {
  border: 1px solid #aeb1bd29 !important;
}

/* .table thead th {
  vertical-align: middle;
  border-bottom-width: 1px;
  border-top: 0;
  border-bottom: 1px solid #e6e7e9 !important;
  padding: 13px 4px;
  min-width: 12.5% !important;
  background-color: #e6e7e9 !important;
  min-height: 48px;
} */

.table thead th {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  border-right: 1px solid #f0f0f0 !important;
}

thead {
  width: 100% !important;
  z-index: 1;
}

tbody {
  max-width: 100% !important;
  width: 100% !important;
  /* line-height: 30px; */
}

.table th,
.table td {
  padding: 5px 8px;
  text-align: left;
  min-width: 12.5%;
  min-height: 48px;
}

tbody tr {
  padding: 0.5rem 0.5rem;
}

@media screen and (max-width: 768px) {
  .table th,
  .table td {
    min-width: 22.28% !important;
    min-width: 139px !important;
  }

  .table thead th {
    /* min-width: 22.28% !important; */
  }
}

.MuiFormControl-root.width {
  width: 100%;
  margin-top: 17px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 20px;
  height: 20px;
  position: relative;
  animation: spinner 1.5s linear infinite;
}

@media screen and (min-width: 990px) {
  .forgot-body {
    width: 317px !important;
  }
}

.loading-spinner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px dotted #000;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite reverse;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 4.5s cubic-bezier(0, 0, 1, -0.5) infinite;
}

.loading-spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px dotted #6d41a1;
  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 4.5s cubic-bezier(0, 0, 1, -0.5) infinite;
}

.dashboard-box {
  display: flex;
  justify-content: space-between;
}

.bg-card-amber {
  background-color: #f7b731;
}

.bg-card-green {
  background-color: #33a433;
}

.bg-card-red {
  background-color: #d24e4e;
}

.module-height-Manager {
  max-height: 120px !important;
  overflow-y: scroll;
}

.module-height-Addon {
  max-height: 250px !important;
  overflow-y: scroll;
}

.justify-content-space-between {
  justify-content: space-between;
}

.border-left {
  border-left: 2px solid #0d0c22;
  margin-left: 6px;
}

.dsr-selected {
  border: 2px solid #000;
  /* Customize the border style as per your requirement */
}

.label-color {
  display: flex;
  justify-content: flex-end;
}

.heading-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.work-flow-danger-status {
  color: #ea2424;
}

.work-flow-sucess-status {
  color: #27a723;
}
.work-flow-warning-status {
  color: #fcd539;
}
.work-flow-info-status {
  color: #4663ac;
}
.work-flow-gray-status {
  color: var(--gray);
}

.d-flex.chart-items {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-end.notification-class {
  display: flex;
  justify-content: space-evenly;
  float: right;
  gap: 12px;
  align-items: center;
}

.Notification {
  display: flex;
}

.Notification input {
  padding: 22px;
  height: 20px;
  width: 20px;
}

.Notification label {
  align-self: center;
  display: flex;
  margin: 0;
  gap: 5px;
}

.table-checkbox-input {
  padding: 18px;
  height: 17px;
  width: 34px;
}

.Dashboard-card {
  height: 126px !important;
}

/* Original styles */
.Dashboard-success-border {
  border-top: 3px solid #09ad95 !important;
}

.Dashboard-loss-border {
  border-top: 3px solid #bb0000 !important;
}

.Dashboard-success-border:hover {
  background-color: #1bc62729 !important;
}

.Dashboard-loss-border:hover {
  background-color: #e6888829 !important;
}

.fuel-card {
  height: 95%;
}

.react-switch-bg {
  background: var(--primary-bg-color) !important;
}

.chart-color-radius {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.d-flex.chart-items > div:first-child {
  margin-right: 0;
  margin-left: 0;
}

.sendemail {
  margin-top: 24px !important;
}

.custom-datatable {
  background-color: lightgray;
  font-size: 16px;
}

[data-role="table"] {
  background-color: #000;
  border: 1px solid #ddd;
}

.addclientbtn {
  display: flex;
  height: 100%;
}

span.ModalTitle {
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 0px;
  color: #000;
  background: #fff;
}

.profile-box {
  background: #fff;
  font-size: 20px;
}

.header-name {
  color: #4663ac;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.email-input {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  border: 1px solid #ecf0fa;
  box-shadow: 1px 1px #e3e3e3;
}

.react-multi-email {
  display: block;
  width: 100%;
  max-height: 70px;
  overflow-y: auto;
  background: #fff;
}

.react-multi-email__input-container {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
}

.renderEmailTag {
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.closeicon {
  cursor: pointer;
  margin-left: 4px;
  font-weight: bold;
}

.react-multi-email.empty input {
  max-height: 70px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: #ddd;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 45px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  box-shadow: 1px 1px #e3e3e3;
}

.mapotions {
  margin-left: 20px;
}

span.fairbank-title {
  font-size: 11px;
  color: #a39999;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  max-height: calc(100% - 64px);
  max-width: 9000px;
}

.itemcount {
  background: #7500ca;
  padding: 0px;
  color: #fff;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: inline-block;
  font-size: 13px;
}

.fuel-site-name {
  cursor: pointer;
  max-width: 139px;
}

.fuel-site-name:hover {
  color: #7500ca !important;
}

.fuel-price-time-td {
  min-width: 120px !important;
}

.fuel-site-time {
  min-width: 105px;
}

.show-pointer-cursor {
  cursor: pointer;
}

.table-data-style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span.Fuelinvenrtytime_range {
  background: #4663ac;
  padding: 4px;
  color: #fff;
  border-radius: 2px;
  max-width: -14px;
  margin-top: 7px;
  margin-left: 0px;
  font-size: 9px;
  margin-left: 0px !important;
}

span.margin-top {
  margin-bottom: 28px;

  margin: 0px;
  gap: 0px;
  display: flex;
  flex-direction: column;
}

.Tank-Details-icon img {
  height: 50px;
  width: 50px;
  margin-left: 7px;
  margin-top: -15px;
  cursor: pointer;
}

.notifications-icon {
  margin-right: 10px;
}

.pulsee {
  display: block;
  position: absolute;
  top: 7px;
  right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #22c03c;
}

.dropdown-menu-end.dropdown-menu-arrow.notifications-menu-width.dropdown-menu.show {
  min-width: 20rem;
  max-width: 20rem;
}

.notifications-menu a {
  padding: 0px !important;
}

.ant-table-cell {
  padding: 10px 10px !important;
}

tr.fuelprice-tr th {
  background: #fafafa;
}

.Disable2FA-modal.modal-body {
  padding: 0px !important;
}

.modal-contentDisable2FA {
  box-shadow: 0px !important;
  padding: 18px !important;
  border: 0px !important;
}

p.f2a img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.modal-dialog1 {
  max-width: 973px;
  margin: 1.75rem auto;
}

.modal-dialog {
  max-width: 973px;
  margin: 1.75rem auto;
}

.instruction-text {
  font-size: 14px;
  font-weight: 700;
}

.f2A-name {
  justify-content: center;
  display: flex;
  color: #000 !important;
  padding: 11px;
  font-size: 14px;
  font-weight: 700;
  background: #dedede;
}

.nodata-image {
  max-height: 350px;
  max-width: 350px;
  margin: auto;
}

.Liveimage {
  max-height: 80px;
  max-width: 80px;
  margin: auto;
}

span.ModalTitle-2fa {
  font-size: 20px;
  font-weight: 700;
  padding: 8px 15px;
  margin-bottom: 0px;
  color: #fff;
  background: #b52d2d;
  border-color: #b52d2d;
}

.header-btn {
  color: #fff;
  background: #b52d2d !important;
  border-color: #b52d2d !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.84615385;
  border-radius: 5px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 100px;
  height: 100%;
  cursor: pointer;
}

.header-icon {
  color: #fff;
  background: #b52d2d !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 19px;
  line-height: 1.84615385;
  border-radius: 5px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.auth-header-icon {
  @media screen and (min-width: "700px") {
    display: none;
  }
}

.auth-header-text {
  @media screen and (max-width: "700px") {
    display: none;
  }
}

.dashboard-shopSale-table-width {
  width: 25%;
}

.dashboard-shopSale-table-th {
  border: 1px solid#aeb1bd !important;
  background-color: #aeb1bd !important;
}

.dashboard-shopSale-table-td {
  padding: 5px 8px;
  text-align: left;
  min-width: 14.28%;
  min-height: 48px;
  border: none !important;
  background: rgb(242, 242, 248) !important;
  margin: 5px 0;
}

.dashboard-child-tdata {
  border: none !important;
  border-bottom: 0.5px solid #ddd !important;
}

.dashboard-child-thead {
  padding-left: 12px !important;
  border: 1px solid #e2e2e2 !important;
}

.dashboard-shop-sale-icon {
  height: 25px;
  width: 25px;
  background: #4663ac;
  border-radius: 50%;
  color: white;
  display: flex;
}

.loader {
  width: 58px;
  height: 58px;
  border: 7px solid #fff;
  border-bottom-color: #4663ac;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.primary-loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #4663ac;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow:
      -38px -12px,
      -14px 0,
      14px 0,
      38px 0;
  }

  33% {
    box-shadow:
      -38px 0px,
      -14px -12px,
      14px 0,
      38px 0;
  }

  66% {
    box-shadow:
      -38px 0px,
      -14px 0,
      14px -12px,
      38px 0;
  }

  100% {
    box-shadow:
      -38px 0,
      -14px 0,
      14px 0,
      38px -12px;
  }
}

@media screen and (min-width: 700px) {
  .dashboard-grads-show-cal {
    display: block !important;
    /* Show the card when the screen width is greater than 700px */
  }

  .dashboard-grads-hide-cal {
    display: none !important;
    /* Hide the card when the screen width is less than or equal to 700px */
  }
}

@media screen and (max-width: 600px) {
  .dashboard-page-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .page-header .breadcrumb {
    margin-top: 0px;
  }

  .breadcrumb-subheader {
    margin-top: 0px !important;
  }

  .manageSite-header {
    margin: 0px;
  }

  .hide-btn-responsive {
    display: none;
  }
}

.MidSmallloader {
  width: 48px;
  height: 48px;
  border: 3px dotted #4663ac;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.MidSmallloader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #ff3d00;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

.Smallloader {
  width: 20px;
  height: 20px;
  border: 3px dotted #4663ac;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.Smallloader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 0px dotted #ff3d00;
  border-style: solid solid dotted;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.single-Competitor-heading {
  background: #aeb1bd;
  padding: 12px;
  border-radius: 5px;
  margin-right: 10px;
  color: black;
  margin: 5px;
  gap: 10px;
}

.single-Competitor-body {
  color: black;
  background: #f2f2f8;
  padding: 12px;
  border-radius: 10px;
  margin-right: 10px;
  margin: 5px;
}

.single-Competitor-distance {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 300;
}

.content_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.card_bg {
  background: url(https://i.ibb.co/M9LB3Kq/Glassmorphism-Background.png);
  background-size: 100%;
  object-fit: fill;
  width: 100%;
  height: 100%;
  background-color: #eee;
}

.my-cardd {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(16.5px);
  -webkit-backdrop-filter: blur(16.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.cardd {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 9px 0 rgb(72 78 153 / 22%);
  backdrop-filter: blur(16.5px);
  -webkit-backdrop-filter: blur(16.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

@media screen and (max-width: "500px") {
  .hidden-in-small-screen {
    display: none;
  }
}

.manage-site-dropdown-item-container {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #6e84a3;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.manage-site-dropdown-item {
  width: 100%;
  padding: 0.5rem 1.5rem;
}

.compi-calender {
  min-width: 200px;
}

span.uk-date-time {
  color: #4663ac;
  padding: 0px 5px;
  font-size: 12px;
  border-radius: 5px;
  background-color: #fff;
}

@media (max-width: 600px) {
  span.uk-date-time {
    display: none;
  }

  span.header-time-section {
    display: none;
  }

  .uk-text-time {
    display: none !important;
  }

  .header-time-empty-section {
    display: none !important;
  }
}

.uk-time {
  margin-left: 6px;
  font-weight: 800;
}

.uk-text-time {
  color: #4663ac;
  padding: 0px 5px;
  font-size: 8.7px;
  background-color: #fff;
  border-right: 1px solid rgb(24 26 26 / 52%);
  border-radius: 4px;
}

.header-time-section {
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  background-color: white;
  border-radius: 4px;
  margin-right: 4px;
}

.header-time-empty-section {
  height: 1px;
  display: flex;
  color: black;
  border-top: 1px solid rgb(24 26 26 / 52%);
}

.company-sage-other-code-table-container {
  width: 300px;
}

@media screen and (max-width: 500px) {
  .company-sage-other-code-table-container {
    width: 100px;
  }
}

span.circle-info {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #7b7c7d;
}

.CardGroupModalItems {
  background-color: #f2f2f8;
  color: black;
  border-radius: 5px;
}

.CardGroupModalHeader {
  background-color: #b6b6b9;
  color: black;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.balance-alert {
  display: flex;
  justify-content: space-between;
}

.balance-badge {
  display: flex;
  background-color: #fff;
  border-color: none;
  padding: 0px 11px;
  border-radius: 7px;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 15px !important;
}

.disable-pre-number {
  background-color: #bdc3c7;
  padding: 0px 7px;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #bdc3c7;
  box-shadow: 1px 1px #e3e3e3;
}

.pay-role-custom-input-box {
  border-radius: 1px dotted #cb2323 !important;
}

.pay-role-table thead th {
  vertical-align: bottom;
  border-top: 0;
  padding: 13px 4px;
  min-width: 14.28% !important;
  min-height: 48px;
  text-align: center;
}

.pay-role-custom-header {
  vertical-align: bottom;
  border-top: 0;
  border-bottom: 1px solid #4663ac !important;
  padding: 13px 4px;
  min-width: 14.28% !important;
  background-color: #4663ac !important;
  min-height: 48px;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

span.input-102 {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

span.input-103 {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  margin-bottom: 0.4rem;
}

.pay-role-input-field {
  padding: 0px 10px;
}

.pay-rol-border {
  border: 1px solid rgb(98, 89, 202);
}

.pay-rol-background-color {
  background-color: rgb(241 242 248);
}

.hide-star-icon {
  display: none;
}

.tree-form {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 20px auto;
}

.tree-list {
  list-style-type: none;
  padding: 0;
}

.tree-item {
  margin-bottom: 8px;
}

.tree-label {
  display: flex;
  align-items: center;
}

.toggle-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 6px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.child-list {
  list-style-type: none;
  margin-top: 6px;
  padding-left: 20px;
}

.child-item {
  margin-bottom: 4px;
}

.child-label {
  display: flex;
  align-items: center;
}

.submit-button {
  background-color: #27ae60;
  color: #fff;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

li.Ul-parentsiteList-childlist {
  background: #2d8ba8bf;
  color: #fff;
  margin-bottom: 10px;
  padding: 1px;
}

.form-payroll-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
}

span.tree-label {
  margin-left: 54px;
  margin-top: 5px;
}

li.ms-2.disabled-checkbox {
  background: #ddd;
  margin-bottom: 3px;
}

.PetrolPrices-img img {
  width: 18px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 12px;
}

.tooltip-inner {
  text-align: left !important;
  max-width: 500px !important;
}

.accordion-button {
  background-color: #fff !important;
  border-bottom: 1px solid #ddd;
}

.manage-site-dropdown-item {
  position: relative;
}

.dropdown-menu {
  z-index: 1000;
  position: absolute;
}

.site_deleted_table.table-responsive .rdt_Table {
  height: 559px;
  overflow: auto;
}

span.text-error {
  background: #b52d2d;
  float: right;
  margin-top: 10px;
  padding: 2px 9px;
  color: #fff;
  text-transform: lowercase;
}

.offcanvas-end {
  width: 50% !important;
}

.statscard {
  cursor: pointer;

  padding: 15px;
}

.statscard:hover {
  background: #dddddd42;
}

.pie-card-default-height {
  height: calc(100% - 25px);
}

.dash-plates-1 {
  background-color: #7e95e4 !important;
}

.dash-plates-2 {
  background-color: #8595cf !important;
}

.dash-plates-3 {
  background-color: #938ddf !important;
}

.dash-plates-4 {
  background-color: #7e95e4 !important;
}

.dash-plates-5 {
  background-color: #9a96c7 !important;
}

.dash-plates-6 {
  background-color: #938ddf !important;
}

.badge-div {
  background: #2d8ba83d;
  border-radius: 5px;
  padding: 7px;
}

.badge {
  background-color: #4663ac;
}

.badge-value {
  font-size: 14px;
}

.badge-key {
  font-size: 14px;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 14px;
}

.swal2-styled.swal2-confirm {
  background-color: #4663ac !important;
}

.page-title {
  color: var(--primary-text-color);
}

.boxtitle {
  font-size: 14px;
  margin-top: 4px;
  text-transform: capitalize;
}

.c-login-img {
  height: 450px;
  width: 450px;
  object-fit: contain;
}

.c-login-right-card {
  background-color: #fff;
}

@media screen and (max-width: 990px) {
  .c-login-left-card {
    /* display: none; */
  }

  .c-login-right-card {
    background-color: unset;
  }
}

@media screen and (max-width: 1500px) {
  .counter-icon {
    /* display: none; */
  }
}

@media screen and (max-width: 1200px) {
  .counter-icon {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .c-dash-badge {
    display: none;
  }
}

.Toastify__progress-bar--success {
  background: var(--primary-bg-color);
}

.pointer {
  cursor: pointer;
}

.c-dashed-line {
  height: 1px;
  border: 1px dashed gray;
}

.c-msg-h {
  max-height: 100px;
  overflow: auto;
}

.custom-matiral-modal > div > div {
  border-radius: 0px !important;
}

.c-f-log-title {
  font-size: 19px;
}

.future-price-log-table
  > .sc-dmRaPn
  > .sc-fLlhyt
  > .rdt_Table
  > .rdt_TableBody
  > .rdt_TableRow
  > .sc-hKMtZM {
  margin: 0px;
  padding: 0px;
}

.future-price-log-table
  > .sc-dmRaPn
  > .sc-fLlhyt
  > .rdt_Table
  > .rdt_TableBody
  > .rdt_TableRow {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.future-back-color {
  height: 100%;
  width: 100%;
}

.dash-card-default-height {
  height: calc(100% - 25px);
}

.fuel-price-conent {
  min-width: 120px;
}

.coffeeandValetInvoiceImage {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.invoice-image-btn {
  position: relative;
  left: -33px;
}

.btn-danger-color {
  color: #b52d2d !important;
}

.btn-primary-color {
  color: var(--primary-bg-color) !important;
}

/* BlankDashboard.css */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.blank-icon-image {
  animation: pulse 3s ease-in-out infinite;
  transition: transform 0.2s ease-in-out;
}

.search-input {
  min-width: 190px;
}

@media (max-width: 768px) {
  .chart-small-screen {
    height: 300px;
  }

  .offcanvas-end {
    width: 100% !important;
  }

  .nodata-image {
    height: 250px;
    width: 250px;
  }

  .page-header {
    margin: 1rem 0rem 1rem;
  }

  .page-title {
    font-size: 16px;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    font-size: 10px;
  }

  .jfrgbA {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .input-time-small-screen {
    min-width: 120px !important;
  }
}

.c-stats-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.middayModal-td .MuiInputBase-input {
  padding: 9.5px 11px !important;
}

.middayModal-time-td .MuiInputBase-input {
  padding: 9.5px 11px !important;
}

.rdt_TableBody {
  min-height: 440px;
  overflow: auto;
}

.dropdown-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  transform: translate(-130px, 19px);
}

.dropdown-menu {
  overflow: hidden;
}

.wrap-text {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.white-text {
  color: #fff !important;
}

.fuel-readonly {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  background-color: #1c8b3359 !important;
}

.update-price-readonly {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
}

.greenboxx {
  width: 15px;
  display: block;
  height: 15px;
  background-color: #1c8b3359;
}

.Readonlyboxx {
  width: 15px;
  display: block;
  height: 15px;
  background-color: #dddddd;
}

.fuel-price-time-td {
  min-width: 96px;
}

.form-check-input-updated {
  left: -32px;
  top: -6px;
}

.input-time-picker {
  border-radius: 0px !important;
}

.input-time-small-screen div {
  border-radius: 0px !important;
}

.middy-table-head {
  padding: 8px 11px !important;
}

.middayModal-time-td {
  width: 175px;
}

/* .modal-content {
  max-width: 600px;
  margin: auto;
  padding: 0px;
  min-width: 300px;
  color: #000;
}

.big-modal .modal-content {
  max-width: fit-content;
  margin: auto;
  padding: 0px;
  min-width: 300px;
  color: #000;
} 
.modal-content {
  width: 100% !important;
}
*/

.profile-drop-down .dropdown-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  transform: initial;
}

.single-Competitor-heading {
  min-height: calc(10px + 66px);
}

.nav-header-box {
  box-shadow: 0px 2px 3px rgb(4 4 7 / 35%);
}

.ph-plus-icon {
  position: relative;
  top: 1px;
}

.ph-search-icons {
  position: relative;
  top: -1px;
}

.c-timeinput-default input {
  color: #000 !important;
}

.font-500 {
  font-weight: 500;
}

.rmsc {
  height: 43px;
}

.matrix-multi > .dropdown-container {
  height: 49px;
  border-radius: 0px !important;
  border: 1px solid #d4d4d4;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: 1px solid #d4d4d4 !important;
  border: 1px solid #d4d4d4 !important;
}

.share-report-body {
  max-height: 200px;
  overflow: auto;
}

.modal-ph-x {
  font-size: 20px;
}

td.middayModal-td.time-input-fuel-sell {
  min-width: 140px !important;
}

.default-pointer {
  cursor: default;
}

.site-setting-table .rdt_TableBody {
  height: 200px !important;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f82649;
  text-transform: lowercase;
}

.invalid-feedback:first-letter {
  text-transform: uppercase;
}

.custom-error-class {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f82649;
  text-transform: lowercase;
}

.custom-error-class:first-letter {
  text-transform: uppercase !important;
}

.rmsc .dropdown-heading {
  height: 43px !important;
}

.color-dark-green {
  color: var(--green-dark);
}

.bg-dark-green {
  background-color: var(--green-dark);
}

.dash-sub-child-header-box {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  background-color: #d63031;
}

.dash-sub-child-header-content {
  height: 27px;
  width: 77%;
  background-color: #ecf0f1;
  border-radius: 8px;
  bottom: 0;
  margin: 6px;
  color: #2d3436;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__triangle {
  left: 112px !important;
}

.login-403 {
  position: relative;
  top: 2px;
}

.Toastify__toast-container {
  z-index: 9999 !important;
  width: 100% !important;
}

.Toastify__toast-container {
  width: calc(100% - 311px) !important;
}

@media screen and (max-width: 950px) {
  .Toastify__toast-container {
    width: calc(100% - 25px) !important;
  }
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: calc(100% - 25px) !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #b52d2d !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #058308 !important;
}

.Toastify__toast {
  border-radius: 0px !important;
}

.coffe-item-category {
  text-transform: capitalize;
  font-size: 12px !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.white-background {
  background-color: #fff;
  color: #ddd;
}

.slide-in-fwd-center {
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.scale-up-ver-top {
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

.tracking-in-contract {
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.rotate-in-ver {
  animation: rotate-in-ver 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes rotate-in-ver {
  0% {
    transform: rotateY(-360deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.tracking-out-contract {
  animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}

@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

.scale-out-horizontal {
  animation: scale-out-horizontal 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}

@keyframes scale-out-horizontal {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    opacity: 1;
  }
}

.slide .angle {
  transition: transform 300ms ease;
}

.slide.is-expanded .angle {
  transform: rotate(90deg);
}

.fuel-site-name-color {
  color: #7500ca;
}

.compidash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 400;
  margin-top: 8px;
}

.height-25 {
  height: calc(100% - 25px);
}

.head-alert-show {
  text-align: left;
  margin: 10px 0;
  font-size: 16px;
  color: white;
  background: #b52d2d;
  padding: 8px;
  border-radius: 7px;
}

.spacebetween {
  display: flex !important;
  justify-content: space-between !important;
}

.iconborder {
  height: 60px;
  width: 60px;
  border: 3px solid #09ad95;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 50%;
  font-size: 10px;
  background-color: #09ad95;
}

.flexspacebetween {
  display: flex;
  justify-content: space-between;
}

.selectedMonth {
  padding: 5px;
  border: 1px solid #ddd;
  min-width: 170px;
  height: 100%;
}

.smalltitle {
  font-size: 12px;
  color: #726060ee;
}

.smallNoDataimg {
  height: 200px;
  width: 200px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.NoDatasmallimg {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.c-fs-18 {
  font-size: 18px;
}

.ph {
  position: relative;
  font-size: 18px;
}

.c-top-3 {
  top: 3px;
}

.cursor-grab {
  cursor: grab;
}

.sortable-chosen {
  background-color: #eaedf1;
}

.drag-tr th {
  padding: 0.7rem 1rem !important;
}

.drag-tr td {
  padding: 0.7rem 1rem !important;
  border-bottom: 1px solid #eaedf1 !important;
}
.textend {
  display: flex;
  justify-content: end;
}
.spacebetweenend {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

:focus-visible {
  border: 1px solid #4663ac !important;
}

.readonly:focus-visible {
  border: 1px solid #cfcfcf !important;
}

.c-w-per-25 {
  width: 25%;
}

.hcenter {
  display: flex;
  justify-content: start;
  align-items: center;
}

.ceo-compi-body {
  height: 565px;
}

.c-line-height-normal {
  line-height: normal;
}

.ceo-compi-head {
  font-size: 12px;
  line-height: normal;
}

.c-width-500 {
  width: 500px !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.hyper-link {
  color: var(--primary-bg-color) !important;
}

.c-fs-18 {
  font-size: 18px !important;
}

.c-p-15 {
  padding: 15px !important;
}

.vertical-iconborder {
  height: 35px;
  width: 35px;
  border: 3px solid #09ad95;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 7px;
  background-color: #09ad95;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-out-right {
  -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-12-10 17:3:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

.ceocard-hover {
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ceocard-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.ceocard-hover h5 {
  font-size: 16px;

  margin: 0;
}

.ceocard-hover .fa {
  font-size: 40px;
}

.ceo-xl-sidebar {
  width: 70% !important;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-10 18:7:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-12-10 18:28:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-10 18:29:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.ceo-sats-card-hover {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease,
    opacity 0.3s ease;
}

.ceo-sats-card-hover:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  z-index: 111;
  cursor: pointer;
}

.ceo-sats-table-hover {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease,
    opacity 0.3s ease;
}

.ceo-sats-table-hover:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  z-index: 111;
}

.card-default-height {
  height: calc(100% - 25px);
}

.c-fs-25 {
  font-size: 25px !important;
}

.ceo-card-default-height {
  height: calc(100% - 15px);
}
.flexcolumn {
  display: flex;
  flex-direction: column;
}

.ceo-stats-blur {
  background: #eeeeee;
  filter: blur(4px);
}
.no-blue-text {
  color: red; /* Or any color you prefer */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ceo-cross-icon {
  top: -8px;
  right: -5px;
  border-radius: 50%;
  padding: 0px;
  max-width: 2px;
  min-width: 15px;
}

.show-ceo-hover-effect-data-table .rdt_TableRow {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease,
    opacity 0.3s ease;
}

.show-ceo-hover-effect-data-table .rdt_TableRow:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  z-index: 111;
}

.performance-col .ddvZdT {
  overflow: visible;
  white-space: unset;
  text-overflow: unset;
}

.ceo-coming-soon-card {
  /* background: url(https://img.freepik.com/free-vector/background-empty-room-with-text-coming-soon_1017-5068.jpg?t=st=1734584371~exp=1734587971~hmac=68ee51dcbe753d148ec989efbb57525f4f6ef64ed7ae01d9c30289a366760996&w=740)
    no-repeat center center; */
  background-size: cover;
  height: 100%;
  background-color: inherit;
}

.operator-tr {
  border-bottom: 2px solid black;
}

.coming-soon-card {
  width: 200px;
  padding: 5px;

  text-align: center;
  background-color: #f00;
  border: 1px solid #f00;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.ribbon-2 {
  --f: 16px; /* control the folded part*/
  --r: 45px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #ff0000;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.MuiDialog-root {
  z-index: 1059 !important;
}

.c-fs-10 {
  font-size: 10px !important;
}

.c-fs-13 {
  font-size: 13px !important;
}

.new-input-checkbox {
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 0.25em;
}

.new-input-checkbox:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.new-input-checkbox:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
  border-radius: 0.25em;
}
.new-input-checkbox:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.new-input-checkbox[type="checkbox"] {
  border-radius: 0.25em;
}

.break-word {
  display: inline-block; /* Keeps it inline but allows wrapping */
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Break long words if necessary */
  word-break: break-all; /* Break long words if necessary */
}

.ceo-live-margin-header {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.min-w-20 {
  min-width: 40px !important;
}
.card-body-icon {
  position: relative;
  background-color: var(--primary-bg-color);
  color: #fff;
}

.pointer {
  cursor: pointer;
}

.default-pointer {
  cursor: default;
}

.public-competitor-price-modal .MuiDialog-paperScrollPaper {
  height: 90vh !important;
}

.public-competitor-price-modal {
  background: linear-gradient(300deg, deepskyblue, darkviolet, blue) !important;
  background-size: 180% 180%;
  animation: gradient-animation-public 18s ease infinite;
}

@keyframes gradient-animation-public {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.public-competitor-price-collapse .ant-collapse-content-box {
  overflow: auto;
}

.h-80 {
  height: 80% !important;
}

.public-competitor-name-title {
  font-size: 20px;
}

.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 130px) !important ;
}

.public-competitor-desc {
  font-size: 23px;
}

@media screen and (max-width: 500px) {
  .MuiDialog-paperScrollPaper {
    margin: 0px !important;
    max-height: max-content !important ;
  }

  .MuiDialogContent-root {
    padding: 20px !important;
  }

  .public-competitor-name-title {
    font-size: 16px;
    gap: 0px !important;
  }
  .public-competitor-desc {
    font-size: 16px;
  }

  .nodata-image {
    object-fit: contain;
  }
}

.c-zindex-100000 {
  z-index: 100000;
}

.main-competitor-effect {
  border: 1px solid #27a723 !important;
}

.c-top-minus-1 {
  top: -1px;
}

.c-fs-12 {
  font-size: 12px !important;
}
.c-min-w-200 {
  min-width: 200px !important;
}
.c-w-50 {
  width: 50% !important;
}

.c-fw-500 {
  font-weight: 500 !important;
}

.circle-num-automation {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fill-reset-color {
  -webkit-text-fill-color: unset !important;
}

.first-cap-container {
  height: 20px;
  width: 20px;
  display: flex;
  background: var(--primary-bg-color);
  color: #fff;
  align-items: center;
  justify-content: center;
}

.blur-timeline {
  background-color: #ffefef !important;
  filter: blur(1px);
}

.cursor-default {
  cursor: default !important;
}

.mop-total-sale-card {
  background: rgb(95, 138, 199);
  background: linear-gradient(
    183deg,
    rgba(95, 138, 199, 1) 29%,
    rgba(32, 85, 154, 1) 100%
  );
  color: #fff;
}

.no-white-space .rdt_TableCol_Sortable > div {
  white-space: unset !important;
  overflow: visible !important;
}

.custom-react-inside-input {
  min-height: 30px !important;
  border-radius: 0px !important;
}

.custom-react-inside-input .__single-value {
  display: flex;
  align-items: center;
}

.custom-react-inside-input .__placeholder {
  display: flex;
  align-items: center;
}

.ScrollbarsCustom-Track {
  width: 3px !important;
}

.custom-checkbox {
  width: 1.5em;
  height: 1.5em;
}

.custom-checkbox[type="checkbox"] {
  border-radius: 0.25em;
}

.custom-checkbox[type="checkbox"] {
  border-radius: 0.25em;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.custom-checkbox:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.custom-checkbox:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.custom-checkbox:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
